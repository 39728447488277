import Layout from "../components/Layout"
import "../assets/css/team.css"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"
import "animate.css"
import React, { useState } from "react"

const Team = ({ data }) => {
  const [show1, setShow1] = useState(true)
  const team = data.allContentfulTeam.edges
  const { image, image2, close, information } = data
  return (
    <Layout>
      <div className="teamHeaderContainer">
        <GatsbyImage
          image={getImage(image)}
          title={image.title}
          alt="Das Team"
        />
        <div className="teamHeader">Das Team</div>
      </div>

      <div className="teamHeader2">Das Team</div>
      <div className="teamHeaderImage">
        <GatsbyImage
          image={getImage(image2)}
          title={image2.title}
          alt="Das Team"
        />
      </div>

      {team.map(({ node }) => (
        <div>
          <div className="teamWrapper animate__animated animate__fadeIn">
            <div className="teamContainer">
              <div className="teamContainerName">{node.name}</div>
              <div className="teamContainerTask">{node.task}</div>
              <div className="teamContainerImageWrapper">
                <div className="teamContainerImage">
                  <GatsbyImage
                    image={getImage(node.image)}
                    title={node.image.title}
                    alt="Team"
                  />
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: node.content.childMarkdownRemark.html,
                  }}
                  className="teamContainerContent"
                ></div>
              </div>
              <div className="teamContainerButtonWrapper">
                <div
                  className={node.informationbutton}
                  onClick={() => setShow1(s => !s)}
                  onKeyDown={() => setShow1(s => !s)}
                  role="button"
                  tabIndex="0"
                >
                  Mehr Infos zur Person
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className="leistungenPopUpContainer">
        <div
          className="leistungenPopUp"
          style={{ display: show1 ? "none" : "block" }}
        >
          <div className="leistungenPopUpClose">
            <div
              className="leistungenPopUpCloseIcon"
              onClick={() => setShow1(s => !s)}
              onKeyDown={() => setShow1(s => !s)}
              role="button"
              tabIndex="0"
            >
              <GatsbyImage
                image={getImage(close)}
                title={close.title}
                alt="Fenster schließen"
                imgStyle={{ width: "35px", height: "35px" }}
              />
            </div>
            <p>Fenster schließen</p>
          </div>

          <div className="leistungenPopUpText">
            <div
              dangerouslySetInnerHTML={{
                __html: information.popuptext.childMarkdownRemark.html,
              }}
            ></div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Team

export const pageQuery = graphql`
  query TeamQuery {
    allContentfulTeam(sort: { fields: [order], order: [ASC] }) {
      edges {
        node {
          name
          task
          image {
            gatsbyImageData(quality: 100)
            title
          }
          content {
            childMarkdownRemark {
              html
            }
          }
          order
          informationbutton
        }
      }
    }

    image: contentfulAsset(contentful_id: { eq: "315iwakuVU4XIlMa8HdJgp" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    image2: contentfulAsset(contentful_id: { eq: "4haTz06TJVkUmfSPoLf9uB" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    close: contentfulAsset(contentful_id: { eq: "xWcjaaSqOPQ9EmLoFGapt" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    information: contentfulTeam(
      contentful_id: { eq: "4TJ0Q5UK7OgQ9B8LpsDIcK" }
    ) {
      popuptext {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
